.video-thumb {
    display: block;
    top: 0px;
    position: relative;
    width: 100%;
    border-radius: 4px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
  }

  .video-thumb:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    cursor: pointer;
  }

  .video-thumb:before {
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  .video-thumb:hover:before {
    transform: scale(2.15);
  }

.video-image {
    width: 100%;   
    height: auto;
    border-radius: 6px;
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}