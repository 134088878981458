.player-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.player {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0;
  left: 0;
  top: 0;
}

.player-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
